
import { defineComponent, reactive } from 'vue'
import DateRangePicker from '@/views/components/dateRangePicker.vue'
import Nav from './components/nav.vue'
import moment from 'moment'

moment.locale('zh-tw')

export default defineComponent({
  setup() {
    const range = reactive({
      start: moment().startOf('day').format('YYYY-MM-DDTHH:mm'),
      end: moment().endOf('day').format('YYYY-MM-DDTHH:mm')
    })

    function updateRange(updateRange: any) {
      range.start = updateRange.start
      range.end = updateRange.end
    }

    return {
      range,
      updateRange
    }
  },
  components: { Nav, DateRangePicker }
})
