
import {
  defineComponent,
  ref,
  reactive,
  computed,
  toRef,
  inject,
  watch
} from 'vue'

import { useStore } from 'vuex'
import moment from 'moment'
import { modalInstance, notifyInstance } from '@/type'
import { useRouter } from 'vue-router'
import exportExcel from '@/plugins/exportExcel'
import usePagination from '@/plugins/usePagination'

moment.locale('zh-tw')

export default defineComponent({
  props: ['propEmployees', 'propRange'],
  setup(props, { emit }) {
    const notify = inject('notify') as notifyInstance
    const modal = inject('modal') as modalInstance

    const store = useStore()
    const router = useRouter()

    const globalVariable = computed(() => store.getters.globalVariable)
    const taxTypeMap = computed(() => globalVariable.value.taxTypes)

    const nowStore = computed(() => store.getters.nowStore)
    const storeId = computed(() => nowStore.value.id)
    const exportType = ref('Invoice')

    let pagination: {
      next: () => any
      pre: () => any
      go: (goPage: number) => any
      getData: () => any
    } | null = null

    const paginationData = reactive({
      page: 1,
      pageSize: 10,
      lastPage: 1,
      total: 0,
      invoiceRecords: ref(new Array<any>())
    })

    watch(
      () => props.propRange,
      async (range: any) => {
        init()
      },
      {
        deep: true
      }
    )

    async function init() {
      paginationData.page = 1
      const paginationSetting = reactive({
        page: toRef(paginationData, 'page'),
        pageSize: toRef(paginationData, 'pageSize'),
        api: `/invoiceRecords/${storeId.value}`,
        payload: {
          startTime: moment(props.propRange.start).format('YYYY-MM-DDTHH:mm'),
          endTime: moment(props.propRange.end).format('YYYY-MM-DDTHH:mm')
        }
      })
      usePagination(paginationSetting).then((res) => {
        pagination = res
        getData()
      })
    }

    function nextPage() {
      const fetchData = pagination?.next()
      fetchData.then(() => {
        getData()
      })
    }

    function prePage() {
      const fetchData = pagination?.pre()
      fetchData.then(() => {
        getData()
      })
    }

    function goPage(page: number) {
      const fetchData = pagination?.go(page)
      fetchData.then(() => {
        getData()
      })
    }

    function getData() {
      const data = pagination?.getData()
      paginationData.page = data.currentPage
      paginationData.lastPage = data.lastPage
      paginationData.pageSize = data.pageSize
      paginationData.total = data.total
      paginationData.invoiceRecords = data.items.map(function (
        value: any,
        index: any
      ) {
        return Object.assign(
          {},
          {
            orderNo: value.orderNo,
            createdAt: value.createdAt,
            invoiceNumber: value.invoiceNumber,
            invoiceAmount: value.invoiceAmount,
            salesAmount: value.salesAmount,
            taxType: value.taxType,
            taxAmount: value.taxAmount,
            carrierId: value.carrierId,
            npoban: value.npoban
          }
        )
      })
    }

    function exportExcelClick() {
      exportExcel(
        exportType.value,
        storeId.value,
        {
          startTime: moment(props.propRange.start).format('YYYY-MM-DDTHH:mm'),
          endTime: moment(props.propRange.end).format('YYYY-MM-DDTHH:mm')
        },
        modal,
        notify,
        router
      )
    }

    function formatNumber(number: any) {
      if (
        number !== undefined &&
        number !== null &&
        number !== '' &&
        !isNaN(Number(number))
      ) {
        return parseInt(number).toLocaleString('en-US')
      }
      return '0'
    }

    init()

    return {
      exportExcelClick,
      paginationData,
      prePage,
      nextPage,
      goPage,
      taxTypeMap,
      formatNumber
    }
  }
})
