
import { defineComponent, ref } from 'vue'
import { useRoute } from 'vue-router'

export default defineComponent({
  setup() {
    const route = useRoute()
    const show = ref(false)
    return {
      route,
      show
    }
  }
})
